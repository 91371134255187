code {
  min-width: 100%;
  max-width: 100%; }
.highlight {
  display: grid;
  margin: 1.25rem 0;
  & & {
    margin: 0; }
  pre {
    padding: 1rem;
    margin: 1.5rem 0;
    background: var(--bubble) !important;
    box-shadow: 0 0 2.5rem rgba(0,0,0,0.12);
    border-radius: 4px;
    overflow-x: auto; } }
