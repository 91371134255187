.waves {
  max-width: 320px;
  height: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 30px auto;
  &_inner {
    min-width: 18rem;
    min-height: 9rem; } }
