* {
  box-sizing: border-box;
  -webkit-appearance: none;
  margin: 0;
  padding: 0; }

body, html {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  font-kerning: normal;
  -webkit-font-feature-settings: "kern" 1;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  -webkit-text-size-adjust: 100%;
  font-size: 100%; }

body {
  font-family: var(--font);
  background-color: var(--bg);
  color: var(--text);
  font-size: 16px;
  line-height: 1.5;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  text-decoration: none;
  color: inherit; }

blockquote {
  opacity: 0.8;
  padding: 1rem;
  position: relative;
  quotes: '\201C''\201D''\2018''\2019';
  margin: 0.75rem 0;
  display: flex;
  flex-flow: row wrap;
  background-repeat: no-repeat;
  background-size: 5rem;
  background-position: 50% 50%;
  position: relative;
  &::before {
    content: "";
    padding: 1px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: var(--theme); }

  p {
    padding-left: 0.5rem 0 !important;
    font-size: 1.1rem !important;
    width: 100%;
    font-weight: 300;
    font-style: italic; } }

h1,h2,h3,h4,h5 {
  font-family: inherit;
  font-weight: 500;
  padding: 5px 0;
  margin: 15px 0;
  color: inherit;
  line-height: 1.35; }

h1 {
  font-size: 200%; }
h2 {
  font-size: 175%; }
h3 {
  font-size: 150%; }
h4 {
  font-size: 125%; }
h5 {
  font-size: 120%; }
h6 {
  font-size: 100%; }

img, svg, video {
  max-width: 100%;
  vertical-align: middle; }
img {
  height: auto;
  margin: 1rem auto;
  padding: 0; }

main {
  padding-bottom: 45px;
  flex: 1; }

ul {
  list-style: none;
  -webkit-padding-start: 0;
  -moz-padding-start: 0; }

b, strong {
  font-weight: 500; }

em, i {
  font-weight: 400 !important;
  font-family: var(--italic);
  font-size: 0.933em;
  color: var(--theme); }
hr {
  border: none;
  padding: 0.5px;
  background: var(--accent);
  margin: 1rem 0; }

aside {
  margin-top: 4rem;
  h3 {
    position: relative;
    margin: 0 !important; } }

span {
  &.pager_link {
    opacity: 0.5; } }

ol {
  counter-reset: my-awesome-counter;
  list-style: none !important;
  padding-left: 3rem;
  margin-left: 0.5rem;
  @media screen and (min-width: 667px) {
 }    // margin-left: 1rem
  li {
    margin: 0 0 0.5rem 0;
    counter-increment: my-awesome-counter;
    position: relative;
    padding-left: 0.5rem !important;
    &::before {
      content: counter(my-awesome-counter);
      font-size: 1.15rem;
      display: inline-grid;
      align-items: center;
      font-weight: 500;
      position: absolute;
      --size: 1.8rem;
      left: -2rem;
      line-height: var(--size);
      width: var(--size);
      height: var(--size);
      color: var(--theme);
      border-radius: 50%;
      text-align: center;
      top: 0.25rem;
      border-right: 2px solid var(--theme); }
    &.align::before {
      top: 1rem; } } }

th, td {
  padding: 0.5rem;
  font-weight: 400 !important;
  &:not(:first-child) {
    padding-left: 1.5rem; } }

thead {
  background: var(--theme);
  color: var(--light);
  font-weight: 400;
  text-align: left; }

tbody {
  tr {
    &:nth-child(even) {
      background-color: var(--accent) !important;
      box-shadow: 0 1rem 0.75rem -0.75rem rgba(0,0,0,0.07); } } }

table {
  margin: 1.5rem 0;
  width:  100%; }
