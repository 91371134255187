$padding: 10px;
$margin: 25px;
$font-path: '../fonts';
$icons: '../images/icons/';
$copy-icon-path: '#{$icons}copy.svg';
$next-icon-path: '#{$icons}next.svg';
$reply-icon-path: '#{$icons}reply.svg';
$close-icon-path: '#{$icons}close.svg';
$never-icon-path: '#{$icons}sitting.svg';

// #161d21; // #1F2A2F; // #132433; // #24292e;
html {
  --light: #fff;
  --dark: #131313;
  --gray: #f5f5f5;
  --bubble: #161718;
  --accent: var(--gray);
  --bg: var(--light);
  --text: var(--dark);
  --theme: #ef7f1a;
  --font: 'Metropolis', sans-serif;
  --italic: 'Volkhov'; } // italic font

@media (prefers-color-scheme: dark) {
  * {
    box-shadow: none !important; }
  html {
    --bg: var(--dark);
    --text: var(--light);
    --accent: var(--bubble); } }

%narrow {
  max-width: 750px;
  margin: 0 auto; }

@mixin viewport($width: 1024px, $margin: 25px) {
  max-width: $width;
  margin: $margin auto;
  @content; }

@mixin shadow($opacity: 0.17) {
  box-shadow: 0 0 3rem rgba(0,0,0,$opacity);
  &:hover {
    box-shadow: 0 0 5rem rgba(0,0,0, (1.5 * $opacity)); } }
