.intro {
  margin: 4rem 0 1rem;
  background: var(--dark);
  padding: 2rem 1.5rem;
  color:  var(--light);
  &_inner {
    @include viewport(59rem);
    margin: 0 auto;
    text-align: center;
    padding: 25px;
    position: relative;
    background-color: var(--bubble);
    border-radius: 1rem;
    section {
      @extend %narrow; } }

  &_headline {
    margin: 0 auto;
    font-weight: 300; }

  &_desc {
    opacity: 0.8;
    text-align: center; } }

@media screen and (min-width: 769px) {
  .nav-menu a {
    display: inline-block;
    height: initial;
    text-align: left; }

  .mobile {
    display:none {} } }

.video {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin: 1.5rem 0;
  border-radius: 1rem;
  background-color: var(--bg);
  box-shadow: 0 1rem 4rem rgba(0,0,0,0.17);
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    transform: scale(1.03); } }

.copy {
  position: relative;
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  svg {
    fill: var(--theme);
    width: 100%;
    height: 100%; }
  &::before, &::after {
    content: "";
    position: absolute;
    background: var(--theme);
    color:  var(--light);
    opacity: 0;
    transition: opacity 0.25s ease-in; }

  &::before {
    content: attr(data-share);
    font-size: 0.8rem;
    width: 5.4rem;
    padding: 0.25rem;
    border-radius: 0.25rem;
    text-align: center;
    top: -2rem;
    right: 0; }

  &_done::before {
    content: attr(data-copied); }

  &:hover::before,
  &:hover::after {
    opacity: 1; } }

.author,
.comment {
  display: grid;
  grid-template-columns: 4rem 1fr;
  grid-gap: 0 0.75rem;
  padding: 1rem;
  margin: 1.5rem 0;
  background-color: var(--accent);
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 2rem rgba(0,0,0,0.12);
  &_name {
    color: var(--text) !important;
    font-size: 1.25rem;
    text-transform: capitalize; }

  &_name, &_pic {
    border: none !important; }

  &_pic {
    padding: 0 0.33rem;
    overflow: hidden;
    img {
      border-radius: 50%;
      margin: 0; } }

  &_bio {
    padding: 0 !important;
    line-height: 1.33; }

  &_heading {
    font-size: 0.7rem; }

  &_meta {
    display: flex;
    flex-flow: column;
    justify-content: center; } }

.modal {
  display: none;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  &_close {
    margin: 25px 0;
    color: var(--light);
    border: 0;
    cursor: pointer;
    padding:$padding/2 $padding*1.5 {} }

  &_inner {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    background: var(--accent);
    max-width: 540px;
    padding: 1.5rem;
    box-shadow: 0 1.5rem 6rem rgba(0,0,0,0.12);
    border-radius: 1rem; }

  &_show {
    overflow: hidden;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      background-color: rgba(0,0,0,0.6); } }

  &_show & {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }

  &_title {
    margin: 0 !important; }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.7);
    z-index: 10; } }

.btn {
  font-size: 1rem;
  margin: 1rem 0 1.5rem;
  padding: 0.5rem 2rem;
  background: var(--theme);
  box-shadow: 0 1rem 4rem rgba(0,0,0,0.5);
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  border-radius: 3rem;
  border: none;
  outline: none;
  &, &_group {
    width: 12rem;
    display: inline-block;
    color:  var(--light); }

  &_group {
    position: relative;
    margin: 1rem 0; }

  &_group & {
    margin: 0; }

  &_close {
    content: "X";
    width: 2.5rem;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: grid;
    align-items: center;
    text-align: center;
    font-size: 1rem;
    background-image: url($close-icon-path);
    background-size: 36% !important;
    border-left: 1px solid var(--bg);
    z-index: 2;
    cursor: pointer;
    margin: 0; } }

.icon {
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat; }

.gallery {
  width: 100%;
  column-count: 3;
  column-gap: 1rem;
  @media screen and (max-width: 667px) {
    column-count: 2; }
  &_item {
    background-color: transparent;
    margin: 0 0 1rem; }
  &_image {
    margin: 0 auto; } }

.crypto {
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 2.5rem;
  position: relative;
  &_logo {
    height: 2.25rem;
    border-radius: 50%;
    margin: 0.5rem; }
  &_row {
    grid-template-columns: 2fr 1fr 1fr;
    padding: 1rem 0;
    grid-gap: 1.5rem;
    position: relative;
    align-items: center;
    cursor: pointer;
    &.active {
      opacity: 0.7; }
    &.active::after {
      content: "";
      position: absolute;
      right: -1rem;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.5rem 1rem 0.5rem 0;
      border-color: transparent var(--accent) transparent transparent; }
    &, &s {
      display: grid; }
    &:not(:last-child) {
      border-bottom: 1px solid var(--accent); } }
  &_address {
    max-width: calc(100% - 100px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:focus, &:hover {
      text-overflow: initial;
      overflow: auto; } }
  svg {
    cursor: pointer;
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    fill: var(--theme); }
  &_qr {
    display: none; }
  &_pad {
    padding: 1.5rem;
    display: none;
    &.active {
      position: absolute;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      border-radius: 0.25rem;
      background: var(--accent);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      max-width: 100%;
      box-shadow: 0 1rem 4rem rgba(0,0,0,0.12); } }
  &_pad &_qr {
    display: initial; } }
